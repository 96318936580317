<template>
    <div>
        <account-role-title role="Admin" />
        <span class="title d-block mb-2">{{ $t("Blacklist") }}</span>
        <div :class="$vuetify.theme.isDark ? 'v-orders-dark' : 'v-orders'">
        <div class="d-flex justify-end pr-8">
            <v-text-field style="max-width: 200px" class="search mx-4" v-model="search" :label="$t('Search ...')" append-icon="mdi-magnify" @input="getSuppliers($event)"/>
            <create-supplier @update="getSuppliersFromApi" />
            <v-dialog
                v-model="isDialogVisible"
                max-width="600px"
            >
                <v-card>
                    <v-card-title class="d-flex justify-space-between">
                        <span class="headline">{{ $t('Update Supplier') }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                            >
                                <v-text-field
                                    :disabled="loadingUpdate"
                                    :rules="sourceRule"
                                    v-model="editData.source"
                                    :label="$t('Source')"
                                    outlined
                                    hide-details="auto"
                                    class="mb-4"
                                />
                                <v-textarea
                                    :disabled="loadingUpdate"
                                    :rules="descriptionRule"
                                    v-model="editData.description"
                                    :label="$t('Description')"
                                    rows="2"
                                    outlined
                                    hide-details="auto"
                                    class="mb-0"
                                />
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            outlined
                            @click="isDialogVisible = false"
                            :disabled="loadingUpdate"
                            class="mr-2"
                        >
                        {{ $t('Close') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            outlined
                            @click="updateSupplier()"
                            :loading="loadingUpdate"
                            :disabled="loadingUpdate"
                        >
                            {{ $t('Update') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
            <v-data-table
                :headers="headers"
                :items="suppliers"
                :options.sync="options"
                :server-items-length="totalSuppliers"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
                    'disable-pagination': loading,
                    'disable-items-per-page': loading,
                }"
                :mobile-breakpoint="100"
                class="unset-table-hover"
                @update:items-per-page="updateItemsPerPage"
                @update:page="getSuppliersFromApi"
                @update:sort-desc="getSuppliersFromApi"
                :items-per-page="globalItemsPerPage"
            >
                <template #[`item.source`]="{item}">
                    <a :href="'https://' + item.source" target="_blank">{{ item.source }}</a>
                </template>
                <template #[`item.user_name`]="{item}">
                    <router-link style="text-decoration: none" :to="{name: 'user', params: {id: item.user_id, tab: 'profile'}}">
                        {{ item.user_name }}
                    </router-link>
                </template>
                <template #[`item.access`]="{item}">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                icon
                                plain
                                v-bind="attrs"
                                v-on="on"
                                class="product-button-dots"
                            >
                                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link @click="editSupplier(item)">
                                <v-list-item-title>
                                    <span class="mb-4">{{ $t('Edit') }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="deleteSupplier(item.id)">
                                <v-list-item-title>{{ $t('Delete') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import {mdiTrashCanOutline, mdiDotsVertical} from '@mdi/js'
import axios from "@axios"
import {ref, watch, computed, getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api"
import store from '@/store'
import CreateSupplier from "@/views/cabinet/suppliers/CreateSupplier";

export default {
    name: 'AdminSuppliersBlackList',
    components: {
        CreateSupplier
    },
    setup() {
        const vm = getCurrentInstance().proxy
        const options = computed(({
            get() {
                return store.state.pagination.suppliers;
            },
            set(val) {
                let page = val.page > val.lastPage ? 1 : val.page
                store.commit("pagination/SET_SUPPLIERS_PAGINATION", Object.assign({}, val, {
                    page
                }))
            }
        }))
        const statusColor = {
            /* eslint-disable key-spacing */
            Unlock: 'success',
            Lock: 'error',
            /* eslint-enable key-spacing */
        }
        const editData = ref({
            id: null,
            source: '',
            description: ''
        })
        const sourceRule = [v => !!v || 'Source link required']
        const descriptionRule = [v => !!v || 'Description required']
        const loadingUpdate = ref(false)
        const valid = ref(true)
        const form = ref(null)
        const timer = ref(null)
        const search = ref('')
        const loading = ref(true)
        const suppliers = ref([])
        const isDialogVisible = ref(false)
        const totalSuppliers = ref(0)
        const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])
        const updateItemsPerPage = (e) => {
            store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e);
            getSuppliersFromApi()
        }
        const editSupplier = (supplier) => {
            editData.value.id = supplier.id
            editData.value.source = supplier.source
            editData.value.description = supplier.description
            isDialogVisible.value = true
        }
        const updateSupplier = () => {
            if (!form.value.validate()) {
                return
            }
            loadingUpdate.value = true
            axios.post('admin/update-supplier-blacklist/' + editData.value.id, {
                source: editData.value.source,
                description: editData.value.description,
            }).then(res => {
                vm.$notify({
                    group: 'deals',
                    title: 'Supplier',
                    type: 'success',
                    text: vm.$t("Supplier successfully updated"),
                })
                loadingUpdate.value = false
                isDialogVisible.value = false
                getSuppliersFromApi()
            }).catch(e => {
                let message = e.response?.status !== 500 && e.response?.data?.message?e.response.data.message:vm.$t("Supplier creation failed")
                vm.$notify({
                    group: 'deals',
                    title: 'Supplier Error',
                    type: 'error',
                    text: message,
                })
                loadingUpdate.value = false
            })
        }
        const deleteSupplier = (id) => {
            axios.delete('admin/delete-supplier-blacklist/' + id).then(res => {
                vm.$notify({
                    group: 'deals',
                    title: 'Supplier',
                    type: 'success',
                    text: vm.$t("Supplier successfully deleted from blacklist"),
                })
                getSuppliersFromApi()
            })
        }
        const getSuppliersFromApi = () => {
            loading.value = true
            const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = options.value
            axios.get('admin/suppliers-blacklist', {
                params: {
                    pagination: globalItemsPerPage.value,
                    page,
                    search: search.value,
                    sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                    sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
                }
            }).then(res => {
                suppliers.value = res.data.data
                totalSuppliers.value = res.data.meta.total
                store.commit("pagination/SET_SUPPLIERS_PAGINATION", { itemsPerPage: globalItemsPerPage.value, page, lastPage: res.data.meta.last_page })
                loading.value = false
            }).catch(e => {
                loading.value = false
                console.log(e)
            })
        }
        const getSuppliers = function (e) {
            if (timer.value) {
                clearTimeout(timer.value)
                timer.value = null
            }
            timer.value = setTimeout(() => {
                getSuppliersFromApi()
            }, 1000)
        }

        getSuppliersFromApi()

        return {
            loadingUpdate,
            updateSupplier,
            sourceRule,
            descriptionRule,
            valid,
            form,
            isDialogVisible,
            editSupplier,
            editData,
            search,
            options,
            loading,
            suppliers,
            totalSuppliers,
            updateItemsPerPage,
            globalItemsPerPage,
            getSuppliersFromApi,
            getSuppliers,
            deleteSupplier,
            headers: [
                {text: 'Date', value: 'created_at', width: 115, sortable: false},
                {text: 'Source', value: 'source', width: 115, sortable: false, width: 240},
                {text: 'Description', value: 'description', sortable: false},
                {text: 'Moderator/Admin', value: 'user_name', sortable: false, width: 200},
                {text: '', value: 'access', sortable: false, width: 10}
            ],
            statusColor,
            status: {
                0: 'Lock',
                1: 'Unlock'
            },
            icons: {
                mdiTrashCanOutline,
                mdiDotsVertical
            },
        }
    }
}
</script>