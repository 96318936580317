<template>
  <v-dialog
      v-model="isDialogVisible"
      max-width="600px"
  >
    <template #activator="{ on, attrs }"> 
        <v-btn-toggle :class="{'mr-3': !buttonText}" style="align-items: center">
          <v-btn
            color="primary"
            v-on="on"
            v-bind="attrs"
        >
            {{ $t(buttonText) }}
          </v-btn>
        </v-btn-toggle>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="headline">{{ $t('Add Supplier') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              :disabled="loading"
              :rules="sourceRule"
              v-model="source"
              :label="$t('Source')"
              outlined
              hide-details="auto"
              class="mb-4"
            />
            <v-textarea
              :disabled="loading"
              :rules="descriptionRule"
              v-model="description"
              :label="$t('Description')"
              rows="2"
              outlined
              hide-details="auto"
              class="mb-0"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            outlined
            @click="isDialogVisible = false"
            :disabled="loading"
            class="mr-2"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
            color="primary"
            outlined
            @click="createSupplier()"
            :loading="loading"
            :disabled="loading"
        >
            {{ $t('Add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref, onUpdated, getCurrentInstance, computed, watch} from '@vue/composition-api'
import axios from "@axios"
import store from '@/store'
import {mdiMenuDown, mdiHelpCircle} from '@mdi/js'

export default {
  props: {
    buttonText: {
      type: String,
      default: 'Add'
    }
  },
  setup(props, {refs}) {
    const vm = getCurrentInstance().proxy
    const sourceRule = [v => !!v || 'Source link required']
    const descriptionRule = [v => !!v || 'Description required']
    const loading = ref(false)
    const valid = ref(true)
    const form = ref(null)
    const description = ref(null)
    const source = ref(null)
    const isDialogVisible = ref(false)

    watch(() => isDialogVisible.value, (newValue) => {
      if(!newValue) form.value = description.value = source.value = null
    })

    const createSupplier = () => {
        if (!form.value.validate()) {
            return
        }
        loading.value = true
        axios.post('admin/create-supplier-blacklist', {
            source: source.value,
            description: description.value,
        }).then(res => {
            vm.$notify({
                group: 'deals',
                title: 'Supplier',
                type: 'success',
                text: vm.$t("Supplier successfully added to blacklist"),
            })
            loading.value = false
            isDialogVisible.value = false
            vm.$emit('update')
        }).catch(e => {
            let message = e.response?.status !== 500 && e.response?.data?.message?e.response.data.message:vm.$t("Supplier creation failed")
            vm.$notify({
                group: 'deals',
                title: 'Supplier Error',
                type: 'error',
                text: message,
            })
            loading.value = false
        })
    }

    return {
    //   clearSupplier,
      descriptionRule,
      sourceRule,
      loading,
      isDialogVisible,
      description,
      source,
      form,
      valid,
      createSupplier,
      icons: {
        mdiMenuDown,
        mdiHelpCircle
      }
    }
  },
}
</script>
